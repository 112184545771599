import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/root';

import ReactGA from 'react-ga';
import { Welcome } from './pages/Welcome';
import ErrorPage from './routes/error-page';
import { Home } from './pages/Home';
import { Pricing } from './pages/Pricing';
const TRACKING_ID = 'G-Z161C7Y8FZ'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: 'welcome',
      element: <Welcome />,
    },
    {
      path: 'pricing',
      element: <Pricing />,
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
      <Helmet>
        <title>DialogAi whatsapp chatbot built with the power of ai</title>
        <meta
          name="description"
          content="Meet Dialogai – the ultimate WhatsApp sidekick for answers on-the-go! Powered by the ingenious ChatGPT, this friendly bot is eager to respond to anything your heart desires. Send a text or simply ask away using a voice message, and ChitChatPal will transcribe and answer in a snap. Get ready to elevate your chats and make conversations a breeze with ChitChatPal – text or talk, we've got it all! 🤖📲🌟"
        />
        <meta
          name="keywords"
          content="Chat with Ai, Dialog with Ai, Whatsapp with Ai, Whatsapp bot"
        />
        <meta name="author" content="Norman Dilthey" />
        <meta
          property="og:title"
          content="DialogAi whatsapp chatbot built with the power of ai"
        />
        <meta
          property="og:description"
          content="Meet Dialogai – the ultimate WhatsApp sidekick for answers on-the-go! Powered by the ingenious ChatGPT, this friendly bot is eager to respond to anything your heart desires. Send a text or simply ask away using a voice message, and ChitChatPal will transcribe and answer in a snap. Get ready to elevate your chats and make conversations a breeze with ChitChatPal – text or talk, we've got it all! 🤖📲🌟"
        />
        <meta property="og:image" content="https://dialogai.io/og.png" />
        <meta property="og:url" content="https://dialogai.io" />
        <meta
          name="twitter:title"
          content="DialogAi whatsapp chatbot built with the power of ai"
        />
        <meta
          name="twitter:description"
          content="Meet Dialogai – the ultimate WhatsApp sidekick for answers on-the-go! Powered by the ingenious ChatGPT, this friendly bot is eager to respond to anything your heart desires. Send a text or simply ask away using a voice message, and ChitChatPal will transcribe and answer in a snap. Get ready to elevate your chats and make conversations a breeze with ChitChatPal – text or talk, we've got it all! 🤖📲🌟"
        />
        <meta name="twitter:image" content="https://dialogai.io/og.png" />
        <meta
          name="twitter:card"
          content="DialogAi whatsapp chatbot built with the power of ai"
        />
      </Helmet>
    </React.StrictMode>
  );
}

export default App;
