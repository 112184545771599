import Navbar from '../components/Navbar';
import HeroSect from '../components/HeroSect';
import RVmsg from '../components/main/RVmsg';
import Talk from '../components/main/Talk';
import GStarted from '../components/main/GStarted';
import Ask from '../components/main/Ask';
import Hints from '../components/main/Hints';
import Follow from '../components/main/Follow';
import FAQ from '../components/FAQ';
import React from 'react';
import { Pricing } from './Pricing';
import Footer from '../components/main/footer';
import Banner from '../components/main/banner';

export const Home = () => {
  return (
    <>
      <Banner />
      <Navbar />
      <HeroSect />
      <main>
        <RVmsg />
        <Talk />
        <GStarted />
        <Ask />
        <Pricing />
        <Hints />
        <Follow />
      </main>
      <FAQ />
      <Footer />
    </>
  );
};
