import Navbar from '../components/Navbar';
import HeroSect from '../components/HeroSect';
import RVmsg from '../components/main/RVmsg';
import Talk from '../components/main/Talk';
import GStarted from '../components/main/GStarted';
import Ask from '../components/main/Ask';
import Hints from '../components/main/Hints';
import Follow from '../components/main/Follow';
import FAQ from '../components/FAQ';
import React from 'react';

export const Pricing = () => {
  return (
    <div className="min-h-screen bg-gray-900">
      <Navbar />
      <h2
        className="pt-20 text-center text-white text-4xl md:text-6xl"
        id="pricing"
      >
        Pricing 💰
      </h2>
      <div className="pt-4 md:pt-20">
        {process.env.NODE_ENV === 'production' && (
          <stripe-pricing-table
            pricing-table-id="prctbl_1PHRRSHRVuNIrnhX4GVvR7nJ"
            publishable-key="pk_live_51MmwEzHRVuNIrnhX5iW6q3ZSPy80XyYRN2e6OFiuSxv5u8BiIYPl3AXeyCGsq13vkgPaq7nMn9OhnZnoA9Nwej9B00h6xdELw2"
          ></stripe-pricing-table>
        )}

        {process.env.NODE_ENV === 'development' && (
          <stripe-pricing-table
            pricing-table-id="prctbl_1PH3SHHRVuNIrnhXIvR252DK"
            publishable-key="pk_test_51MmwEzHRVuNIrnhXxtMMUeLfrpnEVML2Ur77PYaRjK4By2ypjPT8W2qMobzwo1kEIDQ9rRfUTIm4yxXr7L9Fz2s400yJT8VoDJ"
          ></stripe-pricing-table>
        )}
      </div>

      <div className="pb-24 px-6 text-center">
        <div className="mt-12 text-white flex flex-col items-center">
          You can manage your subscription here:
          <a
            href={
              process.env.NODE_ENV === 'production'
                ? 'https://billing.stripe.com/p/login/4gw16q3hGcq7bJK144'
                : 'https://billing.stripe.com/p/login/test_8wM5nRgC22eZbmwcMM'
            }
            className="mt-2 inline-flex justify-between items-center py-2 px-4 mb-7 text-sm text-gray-700 rounded-full border-2 border-[#24d366] dark:text-black hover:bg-gray-200 dark:hover:bg-gray-700"
            role="alert"
          >
            <div className="text-sm font-medium text-white">
              Manage Subscription!
            </div>
            <svg
              className="ml-2 w-5 h-5"
              fill="#fff"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
        <div className="mt-2 text-white">
          <span className="text-sm inline">
            Want to switch to a lifetime plan? Please contact us at{' '}
          </span>
          <a
            href="mailto:hello@dialogai.io?subject=I%20have%20some%20feedback"
            target="_blank"
            rel="noreferrer"
            className="text-sm inline"
          >
            <span className="text-pink-500">hello@dialogai.io</span>
          </a>
        </div>
      </div>
    </div>
  );
};
