import React from 'react';
import Expandible from './Expandible';

const FAQ = () => {
  return (
    <section className="flex flex-col gap-5 mt-8 pt-12 pb-20 px-8 bg-[#5FAEABA3]">
      <h2 className="mb-6 text-center text-4xl font-bold leading-10">FAQ</h2>

      <Expandible title="I have reached the message limit, how can I continue using the chat?">
        Unfortunately, sending whatsapp messages is costly. You can purchase a
        paid plan{' '}
        <a href="#pricing" target="_blank" rel="noreferrer">
          <span className="text-pink-500">here.</span>
        </a>
        <br></br>
        If you prefer using this service for free with advertisements sent to
        you phone number occasionally, please send us some feedback via whatsapp
        with <b>#feedback</b> "your feedback here" or to{' '}
        <a
          href="mailto:hello@dialogai.io?subject=I%20have%20some%20feedback"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-pink-500">hello@dialogai.io</span>
        </a>
      </Expandible>

      <Expandible title="Where is my data stored">
        We are using the most secure state of the art technology to build this
        service. All of your messages will be deleted 10 minutes after saving.
        (The only reason we need to save your messages is for your convenience
        such that the bot knows how to reply to follow up questions)
        <br /> <br />
        The physical location of our servers is in London.
        <br /> <br />
        Your data is not shared with any other third party.
      </Expandible>

      <Expandible title="Where can I provide some feedback?">
        We are happy you want to provide some feedback to us. You can simply
        prefix your feedback whatsapp message with #feedback. For example:{' '}
        <b>!feedback</b> I would like to generate AI images via whatsapp too!
      </Expandible>

      <Expandible title="Do you store the messages that I send to you via Whatsapp?">
        For the Ai to know what you said in previous messages, we store 10
        minutes of chat history. After 10 minutest, your messages will be
        deleted from our servers.
      </Expandible>

      <Expandible title="How can I reach you if I have trouble using the service?">
        Sorry to hear that you are having trouble using the service. Feel free
        to send a message to{' '}
        <a
          href='href="mailto:hello@dialogai.io?subject=I%20have%20some%20feedback"'
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-pink-500">hello@dialogai.io</span>
        </a>
      </Expandible>
    </section>
  );
};

export default FAQ;
