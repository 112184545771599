import React from 'react';
import { NumberedItem } from '../NumberedItem';

const RVmsg = () => {
  return (
    <section className="flex flex-col justify-center py-20" id="how-it-works">
      <h2 className="text-center mt-3 text-3xl md:text-6xl font-bold leading-10">
        Turn voice note into text 🧙
      </h2>

      <div className="mt-12 flex flex-col items-start py-8 sm:py-0 mx-auto pt-8 px-12 gap-3 sm:flex-row">
        <NumberedItem
          title="You forward 💬"
          text="Cannot listen to your voice message right now? Just forward your message to me via whatsapp."
          number="1"
        />
        <NumberedItem
          title="I read ⏳"
          text="Using state of the art AI technology. I will turn your voice message into text. I am using OpenAi tech for this."
          number="2"
        />
        <NumberedItem
          title="Use AI powers 🧙"
          text="Ask me to summarise long voice messages into the main concepts, research unfamiliar topics, or ask me to formulate a nice reply."
          number="3"
        />
      </div>
    </section>
  );
};

export default RVmsg;
