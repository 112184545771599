import React from 'react';
import { RiWhatsappFill } from 'react-icons/ri';

const Navbar = () => {
  return (
    <nav>
      <div className="sm:flex hidden justify-end items-center gap-4 mr-4 mt-6 fixed top-8 inset-x-8">
        <a
          className=" bg-[#24d366] rounded-full py-3 px-8 no-underline max-w-full cursor-pointer"
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send/?phone=447893943425&text=Ask+your+first+question+like%3A+Please+tell+me+a+good+vegan+salad+recipe&type=phone_number&app_absent=0"
        >
          <button className="flex items-center">
            <RiWhatsappFill size={30} color={'white'} />
            <span className="block text-[#fefeff] pl-1.5 font-medium">
              Try DialogAi 😍
            </span>
          </button>
        </a>
        {/*<a*/}
        {/*  className="text-[#ffe615] text-base hover:underline mx-6"*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*  href="https://www.dialogai.io/privacy-policy"*/}
        {/*>*/}
        {/*  Privacy Policy*/}
        {/*</a>*/}
        {/*<a*/}
        {/*  className="text-[#ffe615] text-base hover:underline"*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*  href="https://www.dialogai.io/impressum"*/}
        {/*>*/}
        {/*  Imprint*/}
        {/*</a>*/}
        <a
          className="text-[#ffe615] text-base hover:underline"
          rel="noreferrer"
          href="#pricing"
        >
          Pricing
        </a>
        <a
          className="text-[#ffe615] text-base hover:underline"
          rel="noreferrer"
          href="#how-it-works"
        >
          How it works
        </a>
      </div>

      <div className="flex sm:hidden justify-center fixed bottom-0 inset-x-3 mb-5">
        <a
          className="bg-[#24d366] rounded-full py-3 px-8 max-w-full cursor-pointer"
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send/?phone=447893943425&text=Ask+your+first+question+like%3A+Please+tell+me+a+good+vegan+salad+recipe&type=phone_number&app_absent=0"
        >
          <button className="flex items-center">
            <RiWhatsappFill size={30} color={'white'} />
            <span className="text-[#fefeff] pl-1.5 font-medium">
              Try DialogAi 😍
            </span>
          </button>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
