import React from 'react'
import { ImTwitter } from 'react-icons/im'

const Follow = () => {
    return (
        <section className='flex flex-col items-center py-2 gap-5 mx-0'>
            <h2 className='text-center text-4xl font-bold leading-10'>Follow us 🤙</h2 >
            <div className='flex gap-x-1.5'>
                <ImTwitter />
                <p className='font-thin'>Follow us on Twitter <a href="https://twitter.com/_itsNorman" target="_blank" rel="noreferrer" className='font-bold'>@_itsNorman</a></p>
            </div>
        </section>

    )
}

export default Follow
