import React from 'react';
import { RiWhatsappFill } from 'react-icons/ri';

const GStarted = () => {
  return (
    <section className="flex flex-col py-20 px-5 gap-5">
      <h2 className="text-center text-3xl md:text-6xl font-bold leading-10">
        How to get started 🚀
      </h2>

      <a
        className="hidden sm:flex bg-[#24d366] rounded-full py-3 px-6 max-w-full cursor-pointer justify-center mx-auto w-80 mt-6"
        target="_blank"
        rel="noreferrer"
        href="https://api.whatsapp.com/send/?phone=447893943425&text=Ask+your+first+question+like%3A+Please+tell+me+a+good+vegan+salad+recipe&type=phone_number&app_absent=0"
      >
        <button className="flex items-center">
          <RiWhatsappFill size={30} color={'white'} />
          <span className="block text-[#fefeff] pl-1.5 font-medium">
            Try DialogAi 😍
          </span>
        </button>
      </a>
      <div className="flex flex-col gap-4 mx-auto mt-6">
        <p>
          🔥 To start, click the button, or send a whatsapp message to:{' '}
          <a
            href="tel:+447893943425"
            className="text-[#ff0cdf] hover:underline"
          >
            <span className="inline-block">+44 7893 943425</span>
          </a>
        </p>
        <p>😍 It's as easy as messaging your friends</p>
      </div>
    </section>
  );
};

export default GStarted;
