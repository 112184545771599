import React from 'react'
import img1 from '../images/img1.png'
import img2 from '../images/img2.png'
import img3 from '../images/img3.png'
import img4 from '../images/img4.png'
import img5 from '../images/img5.png'
import desktopImg from '../images/hero-desktop.jpg'

const HeroSect = () => {
    return (
        <>
            <section className='flex sm:hidden bg-[#E74E49] overflow-scroll gap-6 p-5 max-w-min'>
                <div className='flex-none h-680 w-72 p-0.5'>
                    <p className='bg-white p-2.5 h-24 mb-4 rounded-md italic'>Cannot listen to your voice message right now? I got you 🤙 I will turn your voice message into text.</p>
                    <img src={img1} alt="" className='h-560' />
                </div>

                <div className='flex-none h-680 w-72 p-0.5'>
                    <p className='bg-white p-2.5 h-24 mb-4 rounded-md italic'>Ask any question via voice message. By using latest AI technology, I will give you an answer to your question</p>
                    <img src={img2} alt="" className='h-560' />
                </div>

                <div className='flex-none h-680 w-72 p-0.5'>
                    <p className='bg-white p-2.5 h-24 mb-4 rounded-md italic'>You can ask me anything via text as well. For example: do you have any good pizza recipes?</p>
                    <img src={img3} alt="" className='h-560' />
                </div>

                <div className='flex-none h-680 w-72 p-0.5'>
                    <p className='bg-white p-2.5 h-24 mb-4 rounded-md italic'>Another example: how much money do I need to save in order to secure parts of my retirement?</p>
                    <img src={img4} alt="" className='h-560' />
                </div>

                <div className='flex-none h-680 w-72 p-0.5'>
                    <p className='bg-white p-2.5 h-24 mb-4 rounded-md italic'>How can I improve my sleep quality? The only limit is your imagination!</p>
                    <img src={img5} alt="" className='h-560' />
                </div>
            </section>
            <img src={desktopImg} alt="" className='hidden sm:block' />
        </>
    )
}

export default HeroSect
