import React from 'react';

export const NumberedItem = ({ title, text, number }) => {
  return (
    <div className="flex-col max-w-56 p-1">
      <div className="flex gap-2">
        <div className="bg-teal-900 w-9 h-9 rounded-full p-2 flex items-center justify-center text-white text-2xl font-bold">
          {number}
        </div>
        <h5 className="flex items-center gap-2 text-lg font-bold">{title}</h5>
      </div>
      <p className="pt-4 text-md leading-6">{text}</p>
    </div>
  );
};
