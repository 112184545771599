import React from 'react';
import { NumberedItem } from '../NumberedItem';

const Ask = () => {
  return (
    <section className="flex flex-col justify-center py-20 bg-[#24d36617]">
      <h2 className="text-center text-3xl md:text-6xl font-bold leading-10">
        Ask me anything 💡
      </h2>
      <div className="mt-4 flex flex-col items-start py-8 sm:py-0 mx-auto pt-8 px-12 gap-3 sm:flex-row">
        <NumberedItem
          title="Ask a question 🙋"
          text="Just like you would ask google, just send me a whatsapp and ask your question"
          number="1"
        />
        <NumberedItem
          title="I have the answer 💡"
          text="You can ask me anything your heart desires, and I will use AI to answer your question as best I can."
          number="2"
        />
        <NumberedItem
          title="Repeat 🎉"
          text="You can ask follow up questions via voice or text, I will remember what you said in our chat. I save our communication context for 10 minutes."
          number="3"
        />
      </div>
    </section>
  );
};

export default Ask;
