import React from 'react';

export const Welcome = () => {
  return (
    <>
      <main className="h-full dark:bg-gray-900">
        <section className="dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
            <a
              href="https://wa.me/447893943425"
              className="inline-flex justify-between items-center py-2 px-4 mb-7 text-sm text-gray-700 rounded-full bg-[#24d366] dark:text-black hover:bg-gray-200 dark:hover:bg-gray-700"
              role="alert"
            >
              <div className="text-sm font-medium">Go to the bot directly!</div>
              <svg
                className="ml-2 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>

            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
              Welcome to DialogAi!
            </h1>
            <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
              Happy to see you try DialogAi, your personal Whatsapp Ai assistant
              to turn voicenotes to text and answer your questions.
            </p>
            <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <div
                className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                onClick={() => {
                  document.getElementById('instructions').scrollIntoView();
                }}
              >
                {/*<svg*/}
                {/*  className="mr-2 -ml-1 w-5 h-5"*/}
                {/*  fill="currentColor"*/}
                {/*  viewBox="0 0 20 20"*/}
                {/*  xmlns="http://www.w3.org/2000/svg"*/}
                {/*>*/}
                {/*  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>*/}
                {/*</svg>*/}
                👇Here is some instructions on how to use the bot!👇
              </div>
            </div>
            <div className="text-left text-sm text-white" id="instructions">
              <p>
                👂 You can forward voice messages to me, and I will convert them
                into text.
              </p>
              <p>
                📝 We can continue the conversation via text, or you can send
                another voice message.
              </p>
              <p>❤️ You can ask me anything you want.</p>
              <p className="mt-6">
                Enter one of the following hashtags in a message to me to
                interact:
              </p>
              <a
                href="https://wa.me/447893943425"
                className="mt-6 inline-flex justify-between items-center py-2 px-4 mb-7 text-sm text-gray-700 rounded-full bg-[#24d366] dark:text-black hover:bg-gray-200 dark:hover:bg-gray-700"
                role="alert"
              >
                <div className="text-sm font-medium">
                  Go to the bot directly!
                </div>
                <svg
                  className="ml-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
              <ul className="mt-2 list-disc pl-10 gap-2 flex flex-col">
                <li>
                  🚀 <strong>#share</strong> if you want to share DialogAi with
                  a friend and benefit from it (you receive more free messages
                  and transcription time)
                </li>
                <li>
                  💰 <strong>#payment</strong> if you want to purchase a paid
                  account for unlimited messages
                </li>
                <li>
                  ❓ <strong>#info</strong> if you want to know how many
                  messages and audio transcription time you still have on your
                  free account
                </li>
                <li>
                  🫶 <strong>#feedback</strong> to send any feedback you might
                  have :)
                </li>
                <li>
                  👋 <strong>#cancel</strong> to cancel your membership
                </li>
                <li>
                  🔥 <strong>#delete</strong> to manually delete your message
                  history (your messages are automatically deleted after 10
                  minutes)
                </li>
                <li>
                  🛑 <strong>#stop-news</strong> to stop receiving messages from
                  us
                </li>
              </ul>
              <p>
                If you need further assistance, please contact us at{' '}
                <a href="mailto:hello@dialogai.io">hello@dialogai.io</a> or send{' '}
                <strong>'!support'</strong> and your message on WhatsApp.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
