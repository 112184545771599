import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const Expandible = ({ title, children }) => {
    const [drop, setDrop] = useState(false);
    const handleDrop = () => {
        setDrop(!drop);
    };

    return (
        <>
            <div onClick={() => setDrop(!drop)} className="bg-white py-4 px-4 rounded-xl mx-auto md:w-3/4 w-full">
                <div className="flex justify-between hover:cursor-pointer">
                    <h5 onClick={handleDrop} className=" text-lg font-bold flex">
                        {title}
                    </h5>
                    <div>{!drop ? <RiArrowDropDownLine size={40} /> : <RiArrowDropUpLine size={40} />}</div>
                </div>
                <p className={!drop ? "hidden" : "pt-2"}>{children}</p>
            </div>
        </>
    );
};

export default Expandible;
