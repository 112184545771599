import React from 'react';

const Hints = () => {
  return (
    <section className="py-20 px-4">
      <h2 className="mb-5 text-center mt-3 text-3xl md:text-6xl font-bold leading-10">
        Some Hints 🏛️
      </h2>

      <div className="flex flex-col gap-5 w-auto max-w-2xl mx-auto px-5 mt-12">
        <p>
          💡 Send your question to the phone number above and I will reply with
          the answer from ChatGpt via Whatsapp.
        </p>

        <p>
          🫶 You can send any voice note to me and I will turn it into text for
          you. Plans for <b>unlimited messages</b> start at $3.99/month.*
        </p>

        <p>
          🥳 Have fun! If you have feedback:
          <a
            href="mailto:hello@dialogai.io?subject=Hey%2C%20I%20have%20some%20feedback"
            className="text-[#ff0cdf] hover:underline"
          >
            Say hi
          </a>
        </p>

        <p className="text-xs">
          * I have to limit the number of words in my reply to roughly 250. For
          most answers this will not be an issue. We are working on another paid
          plan which gets rid of this limit
        </p>
      </div>
    </section>
  );
};

export default Hints;
