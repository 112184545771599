import { Footer as FlowbiteFooter } from 'flowbite-react';

export default function Footer() {
  return (
    <FlowbiteFooter container>
      <div className="w-full text-center">
        <span>
          Made with ❤️ by
          <a
            className="underline"
            href="https://diltheymedia.com"
            target="_blank"
            title="DiltheyMedia - Ihr Strategischer Partner für KI und Software Entwicklung"
          >
            DiltheyMedia
          </a>
        </span>
        {/*<FlowbiteFooter.Copyright href="#" by="Flowbite™" year={2022} />*/}
      </div>
    </FlowbiteFooter>
  );
}
