export default function Banner() {
  return (
    <div className="fixed flex justify-center items-center text-white inset-0 w-full text-center h-12 bg-blue-950">
      <span>
        👉👉 Interested in building AI applications? Contact us at{' '}
        <a
          className="underline"
          href="https://diltheymedia.com"
          target="_blank"
          title="DiltheyMedia - Ihr Strategischer Partner für KI und Software Entwicklung"
        >
          DiltheyMedia
        </a>{' '}
        👈👈
      </span>
    </div>
  );
}
