import React from 'react';
import { NumberedItem } from '../NumberedItem';

const Talk = () => {
  return (
    <section className="flex flex-col justify-center py-20 bg-[#929ECA7D]">
      <h2 className="text-center mt-3 text-3xl md:text-6xl font-bold leading-10">
        Talk to me 💬
      </h2>

      <div className="mt-12 flex flex-col items-start py-8 sm:py-0 mx-auto pt-8 px-12 gap-3 sm:flex-row">
        <NumberedItem
          title="Record a question 🎤"
          text='Record a voice message in our chat. Make sure to start your voice with the word "question"'
          number="1"
        />
        <NumberedItem
          title="I answer 💡"
          text="Using chat GPT in the background, I will listen to the question you have and answer it as best I can."
          number="2"
        />
        <NumberedItem
          title="Repeat 🎉"
          text="You can ask follow up questions via voice or text, I will remember what you said in our chat. I save our communication context for 10 minutes."
          number="3"
        />
      </div>
    </section>
  );
};

export default Talk;
